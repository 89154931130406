exports.components = {
  "component---src-pages-consulting-404-js": () => import("./../../../src/pages/consulting/404.js" /* webpackChunkName: "component---src-pages-consulting-404-js" */),
  "component---src-pages-consulting-file-not-found-js": () => import("./../../../src/pages/consulting/file_not_found.js" /* webpackChunkName: "component---src-pages-consulting-file-not-found-js" */),
  "component---src-pages-develop-startpage-js": () => import("./../../../src/pages/develop/startpage.js" /* webpackChunkName: "component---src-pages-develop-startpage-js" */),
  "component---src-pages-ie-not-supported-js": () => import("./../../../src/pages/ie_not_supported.js" /* webpackChunkName: "component---src-pages-ie-not-supported-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/notFoundPage.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---templates-article-js": () => import("./../../../templates/article.js" /* webpackChunkName: "component---templates-article-js" */),
  "component---templates-blog-authors-cards-js": () => import("./../../../templates/blogAuthorsCards.js" /* webpackChunkName: "component---templates-blog-authors-cards-js" */),
  "component---templates-blog-category-js": () => import("./../../../templates/blogCategory.js" /* webpackChunkName: "component---templates-blog-category-js" */),
  "component---templates-blog-js": () => import("./../../../templates/blog.js" /* webpackChunkName: "component---templates-blog-js" */),
  "component---templates-blog-main-page-js": () => import("./../../../templates/blogMainPage.js" /* webpackChunkName: "component---templates-blog-main-page-js" */),
  "component---templates-blog-search-js": () => import("./../../../templates/blogSearch.js" /* webpackChunkName: "component---templates-blog-search-js" */),
  "component---templates-event-js": () => import("./../../../templates/event.js" /* webpackChunkName: "component---templates-event-js" */),
  "component---templates-events-history-page-js": () => import("./../../../templates/eventsHistoryPage.js" /* webpackChunkName: "component---templates-events-history-page-js" */),
  "component---templates-events-main-page-js": () => import("./../../../templates/eventsMainPage.js" /* webpackChunkName: "component---templates-events-main-page-js" */),
  "component---templates-full-screen-gallery-js": () => import("./../../../templates/fullScreenGallery.js" /* webpackChunkName: "component---templates-full-screen-gallery-js" */),
  "component---templates-gallery-js": () => import("./../../../templates/gallery.js" /* webpackChunkName: "component---templates-gallery-js" */),
  "component---templates-news-room-main-page-js": () => import("./../../../templates/newsRoomMainPage.js" /* webpackChunkName: "component---templates-news-room-main-page-js" */),
  "component---templates-newsletter-archive-page-js": () => import("./../../../templates/newsletterArchivePage.js" /* webpackChunkName: "component---templates-newsletter-archive-page-js" */),
  "component---templates-page-js": () => import("./../../../templates/page.js" /* webpackChunkName: "component---templates-page-js" */),
  "component---templates-product-js": () => import("./../../../templates/product.js" /* webpackChunkName: "component---templates-product-js" */),
  "component---templates-sitemap-page-js": () => import("./../../../templates/sitemapPage.js" /* webpackChunkName: "component---templates-sitemap-page-js" */),
  "component---templates-tag-js": () => import("./../../../templates/tag.js" /* webpackChunkName: "component---templates-tag-js" */),
  "component---templates-user-js": () => import("./../../../templates/user.js" /* webpackChunkName: "component---templates-user-js" */),
  "component---templates-videogallery-js": () => import("./../../../templates/videogallery.js" /* webpackChunkName: "component---templates-videogallery-js" */),
  "slice---src-slices-footer-footer-js": () => import("./../../../src/slices/Footer/Footer.js" /* webpackChunkName: "slice---src-slices-footer-footer-js" */),
  "slice---src-slices-header-header-js": () => import("./../../../src/slices/Header/Header.js" /* webpackChunkName: "slice---src-slices-header-header-js" */)
}

